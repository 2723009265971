// ProductForm.js
import React, { useState } from 'react';
import productsData from '../data/products.json';
import { useCheckout } from '../context/CheckoutContext';
import { useTranslation } from '../context/TranslationContext';

const ProductForm = () => {
    const { translations, isRTL } = useTranslation();

    const { addToCheckout } = useCheckout();
    const [categories] = useState(productsData.categories);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [quantity, setQuantity] = useState(1);

    const handleAddProduct = () => {
        if (selectedProduct && quantity > 0) {
            const category = categories.find(cat => cat.name === selectedCategory);
            if (category) {
                const productDetails = category.products.find(product => product.name === selectedProduct);

                addToCheckout({
                    name: selectedProduct,
                    category: selectedCategory,
                    price: productDetails.price,
                    quantity: parseInt(quantity),
                });

                setSelectedProduct('');
                setQuantity(1);
            }
        }
    };



    return (
        <div className={`bg-white p-6 rounded-md shadow-md ${isRTL ? 'rtl' : ''}`}>
            <h2 className={`text-xl font-semibold mb-4  ${isRTL ? 'text-right' : ''}`}>
                {translations.products}
            </h2>

            <div className="mb-4">
                <label htmlFor="category" className={`block text-gray-600 ${isRTL ? 'text-right' : ''}`}>
                    {translations.Select_category}
                </label>
                <select
                    id="category"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className={`w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500  ${isRTL ? 'text-right' : ''}`}
                    dir={isRTL ? 'rtl' : 'ltr'}
                >
                    <option value="">{translations.Select_category}</option>
                    {categories.map(category => (
                        <option key={category.name} value={category.name}>
                            {category.name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="mb-4">
                <label htmlFor="name" className={`block text-gray-600 ${isRTL ? 'text-right' : ''}`}>
                    {translations.Select_Product}
                </label>
                <select
                    id="productSelect"
                    value={selectedProduct}
                    onChange={(e) => setSelectedProduct(e.target.value)}
                    className={`w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500  ${isRTL ? 'text-right' : ''}`}
                    dir={isRTL ? 'rtl' : 'ltr'}
                >
                    <option value="">{translations.Select_Product}</option>
                    {selectedCategory &&
                        categories
                            .find(category => category.name === selectedCategory)
                            .products.map(product => (
                                <option key={product.name} value={product.name}>
                                    {`${product.name} - ${product.price.toFixed(2)} ${product.currency}`}
                                </option>
                            ))}
                </select>
            </div>

            <div className="mb-4">
                <label htmlFor="quantity" className={`block text-gray-600 ${isRTL ? 'text-right' : ''}`}>
                    {translations.quantity}
                </label>
                <input
                    type="number"
                    id="quantity"
                    min="1"
                    value={quantity}
                    onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value)))}
                    className={`w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500  ${isRTL ? 'text-right' : ''}`}
                    dir={isRTL ? 'rtl' : 'ltr'}
                />
            </div>

            <button
                onClick={handleAddProduct}
                className={`bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 focus:outline-none focus:ring focus:border-gray-500 ${isRTL ? 'rtl' : ''}`}
            >
                {translations.add_product}
            </button>
        </div>
    );
};

export default ProductForm;
