import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useSuccessContext } from '../context/SuccessContext';
import { useTranslation } from '../context/TranslationContext';

const SuccessForm = () => {
    const { setSuccessState } = useSuccessContext();
    const { translations } = useTranslation();
    const handleAnotherOrder = () => {
        setSuccessState(false); // Reset success state to false
        // Add any additional logic for handling another order
        // For example, you can navigate to another page or perform other actions
    };

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="bg-white p-8 rounded-md shadow-md text-center">
                <div className="flex items-center justify-center mb-4">
                    <FaCheckCircle className="text-green-500 text-4xl" />
                </div>
                <h2 className="text-2xl font-bold mb-4">{translations.order_Placed_Placed}</h2>
                <p
                    className="text-blue-500 cursor-pointer underline"
                    onClick={handleAnotherOrder} // Use the updated function
                >
                    {translations.place_another_order}
                </p>
            </div>
        </div>
    );
};

export default SuccessForm;
