// CheckoutItem.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './../styles/tailwind.css';
import { useCheckout } from '../context/CheckoutContext';
import { useTranslation } from '../context/TranslationContext';

const CheckoutItem = ({ item, index }) => {
    const { isRTL } = useTranslation();
    const { updateQuantity, removeFromCheckout } = useCheckout();

    const handleRemoveItem = () => {
        removeFromCheckout(index);
    };

    const handleQuantityChange = (amount) => {
        // If the new quantity is 0 or less, remove the item
        if (item.quantity === 1 && amount === -1) {
            removeFromCheckout(index);
        } else {
            // If the new quantity is 0 or less, remove the item

            updateQuantity(index, item.quantity + amount);
        }
    };

    return (
        <div key={item.index} className="flex items-center justify-between border-b py-2">
            {isRTL && (
                <div className={`cursor-pointer text-red-500 `} onClick={handleRemoveItem}>
                    <FontAwesomeIcon icon={faTrash} />
                </div>
            )
            }
            <div className={`text-${isRTL ? 'right' : 'left'}`}>
                <p className={`text-black-900 ${isRTL ? 'text-right' : ''}`}>{item.name}</p>
                <p className={`text-gray-600 ${isRTL ? 'text-right' : ''}`}>{item.category}</p>
                <div className={` space-x-2 ${isRTL ? 'text-right' : ''}`}>
                    <span className={`text-gray-800 ${isRTL ? 'text-right' : ''}`}>{(item.price * item.quantity).toFixed(2)}</span>
                </div>
                <div className={` ${isRTL ? 'text-right' : ''}`}>

                    <span
                        className={`cursor-pointer ${isRTL ? 'text-red-500 rtl' : 'text-red-500'}`}
                        onClick={() => handleQuantityChange(-1)}
                    >
                        -
                    </span>

                    <span className={`text-gray-800 ${isRTL ? 'text-right' : ''}`}>{item.quantity}</span>

                    <span
                        className={`cursor-pointer ${isRTL ? 'text-green-500 rtl' : 'text-green-500'}`}
                        onClick={() => handleQuantityChange(1)}
                    >
                        +
                    </span>

                </div>
            </div>
            {!isRTL && (
                <div className={`cursor-pointer text-red-500 `} onClick={handleRemoveItem}>
                    <FontAwesomeIcon icon={faTrash} />
                </div>
            )}
        </div>
    );
};

export default CheckoutItem;