// CheckoutItem.js
import React from 'react';
import './../styles/tailwind.css';
import { useTranslation } from '../context/TranslationContext';

const SummaryItem = ({ item }) => {
    const { isRTL } = useTranslation();




    return (
        <div className="justify-between border-b py-2">
            <div className={`text-${isRTL ? 'right' : 'left'}`}>
                <p className={`text-gray-600 ${isRTL ? 'text-right' : ''}`}>{item.name}</p>

                <div className={` space-x-2 ${isRTL ? 'text-right' : ''}`}>
                    <span className={`text-gray-800 ${isRTL ? 'text-right' : ''}`}>{(item.price * item.quantity).toFixed(2)}</span>
                </div>
                <div className={` ${isRTL ? 'text-right' : ''}`}>

                    <span className={`text-gray-800 ${isRTL ? 'text-right' : ''}`}>{item.quantity}</span>

                </div>
            </div>

        </div>
    );
};

export default SummaryItem;