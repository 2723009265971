// CheckoutContext.js
import { createContext, useContext, useState } from 'react';

const CheckoutContext = createContext();

export const CheckoutProvider = ({ children }) => {
    const [checkoutItems, setCheckoutItems] = useState([]);
    const addToCheckout = (item) => {
        const existingItemIndex = checkoutItems.findIndex(existingItem => existingItem.name === item.name);

        if (existingItemIndex !== -1) {

            const updatedCheckoutItems = [...checkoutItems];
            updatedCheckoutItems[existingItemIndex].quantity += item.quantity;
            setCheckoutItems(updatedCheckoutItems);
        } else {

            setCheckoutItems([...checkoutItems, item]);
        }
    };
    const removeFromCheckout = (index) => {
        const updatedCheckoutItems = [...checkoutItems];
        updatedCheckoutItems.splice(index, 1);
        setCheckoutItems(updatedCheckoutItems);
    };

    const updateQuantity = (index, newQuantity) => {
        const updatedCheckoutItems = [...checkoutItems];
        updatedCheckoutItems[index].quantity = newQuantity;
        setCheckoutItems(updatedCheckoutItems);
    };
    const getTotalAmount = () => {
        return checkoutItems.reduce((total, item) => total + item.price * item.quantity, 0);
    };
    const resetCheckout = () => {
        setCheckoutItems([]);
    };
    return (
        <CheckoutContext.Provider
            value={{
                checkoutItems,
                addToCheckout,
                removeFromCheckout,
                updateQuantity,
                getTotalAmount,
                resetCheckout

            }}
        >
            {children}
        </CheckoutContext.Provider>
    );
};

export const useCheckout = () => {
    const context = useContext(CheckoutContext);
    if (!context) {
        throw new Error('useCheckout must be used within a CheckoutProvider');
    }
    return context;
};
