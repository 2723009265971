// App.js
import React from 'react';
import { SuccessProvider } from './context/SuccessContext';
import Container from './components/Container';
import { CheckoutProvider } from './context/CheckoutContext';
import { TranslationProvider } from './context/TranslationContext'
const App = () => {
  return (
    <TranslationProvider>
      <CheckoutProvider>
        <SuccessProvider>
          <Container />
        </SuccessProvider>
      </CheckoutProvider>
    </TranslationProvider>
  );
};

export default App;
