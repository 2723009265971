import React, { useState } from 'react';
import PaymentConfirmation from './PaymentConfirmation';
import ShoppingCart from './ShoppingCart';
import { useSuccessContext } from '../context/SuccessContext';
import SuccessForm from './SuccessForm';
import { useTranslation } from '../context/TranslationContext';

const Container = () => {
    const [showPaymentConfirmation, setShowPaymentConfirmation] = useState(false);
    const { success } = useSuccessContext();
    const { language, setLanguage } = useTranslation();
    const handlePay = () => {
        setShowPaymentConfirmation(true);
    };
    const goBack = (state) => {
        setShowPaymentConfirmation(state);
    };




    return (
        <>

            <div>
                <select
                    id="languageSelect"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                >
                    <option value="en">English</option>
                    <option value="fr">Français</option>
                    <option value="ar">العربية</option>
                </select>
            </div>


            {success ? (
                <SuccessForm handlePlaceAnotherOrder={() => setShowPaymentConfirmation(false)} />
            ) : (
                <>
                    {showPaymentConfirmation ? (
                        <PaymentConfirmation
                            goBack={goBack}
                        />
                    ) : (
                        <ShoppingCart
                            handlePay={handlePay}

                        />
                    )}
                </>
            )}
        </>
    );
};

export default Container;