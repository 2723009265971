import React, { createContext, useContext, useState, useEffect } from 'react';

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
    const [language, setLanguage] = useState('ar');
    const [translations, setTranslations] = useState({});
    const [isRTL, setIsRTL] = useState(false);
    useEffect(() => {
        const loadTranslations = async () => {
            try {
                const translationModule = await import(`../local/${language}.json`);
                const translationData = translationModule.default;
                setTranslations(translationData);
                setIsRTL(translationData.direction === 'rtl');
            } catch (error) {
                console.error('Error loading translations:', error);
            }
        };

        loadTranslations();

    }, [language]);

    return (

        <TranslationContext.Provider value={{ isRTL, language, setLanguage, translations }}>
            {children}
        </TranslationContext.Provider>
    );
};

export const useTranslation = () => {
    const context = useContext(TranslationContext);
    if (!context) {
        throw new Error('useTranslation must be used within a TranslationProvider');
    }
    return context;
};
