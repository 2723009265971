import React from 'react';
import AddressForm from './AddressForm';
import SummaryCheckout from './SummaryCheckout';

const PaymentConfirmation = ({ goBack }) => {

    return (

        <>
            <div className="flex flex-col md:flex-row h-[86vh]">
                <div className="md:w-1/2 p-4 flex-1">
                    <div className="overflow-hidden md:hidden mb-4">
                        <AddressForm goBack={goBack} />
                    </div>
                    <div className="md:ml-4">
                        <SummaryCheckout />
                    </div>
                </div>
                <div className="md:w-1/2 p-4 hidden md:block flex-1">

                    <AddressForm goBack={goBack} />
                </div>
            </div>

        </>



    );
};

export default PaymentConfirmation;
