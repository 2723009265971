// SummaryCheckout.js
import React, { useEffect, useState } from 'react';
import SummaryItem from './SummaryItem';
import { useCheckout } from '../context/CheckoutContext';
import { useTranslation } from '../context/TranslationContext';

const SummaryCheckout = () => {
    const { translations, isRTL } = useTranslation();
    const { checkoutItems, getTotalAmount } = useCheckout();
    const [maxHeight, setMaxHeight] = useState(0);


    // useEffect to calculate maxHeight
    useEffect(() => {
        const pageHeight = window.innerHeight;
        const headerHeight = 100; // Adjust this value based on the height of your header or other fixed elements
        const scrollableArea = pageHeight - headerHeight;
        const minHeightBeforeScroll = 0.6; // 50% of viewport height
        setMaxHeight(scrollableArea * minHeightBeforeScroll);
    }, []);
    console.log("isRTL,", isRTL)
    return (
        <div className="flex flex-col h-[90]">
            <div className={`bg-white p-6 shadow-md rounded-md flex-1 overflow-hidden ${isRTL ? 'rtl' : ''}`}>

                <h2 className={`text-xl font-semibold mb-4  ${isRTL ? 'text-right' : ''}`}>{translations.order_summary}</h2>
                <div className="overflow-y-auto" style={{ maxHeight: `${maxHeight}px` }}>
                    <div className="mb-8">
                        {checkoutItems.map((item, index) => (
                            <SummaryItem
                                key={index}
                                item={item}
                                index={index}
                            />
                        ))}
                    </div>
                </div>

                <div className={`mt-4 flex justify-between items-center ${isRTL ? 'rtl' : ''}`}>
                    <span className={`text-xl font-semibold   ${isRTL ? 'text-right' : 'text-left'}`}>{translations.total}</span>
                    <span className={`text-xl font-semibold   ${isRTL ? 'text-left' : 'text-right'}`}>{getTotalAmount().toFixed(2)}DA</span>
                </div>
            </div>


        </div >
    );
};

export default SummaryCheckout;
