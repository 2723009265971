import React, { useEffect, useState } from 'react';
import CheckoutItem from './CheckoutItem';
import { useCheckout } from '../context/CheckoutContext';
import { useTranslation } from '../context/TranslationContext';

const Checkout = ({ handlePay }) => {
    const { translations, isRTL } = useTranslation();
    console.log(isRTL)
    const { checkoutItems, getTotalAmount } = useCheckout();
    const [maxHeight, setMaxHeight] = useState(0);
    // useEffect to calculate maxHeight
    useEffect(() => {
        const pageHeight = window.innerHeight;
        const headerHeight = 100; // Adjust this value based on the height of your header or other fixed elements
        const scrollableArea = pageHeight - headerHeight;
        const minHeightBeforeScroll = 0.6; // 50% of viewport height
        setMaxHeight(scrollableArea * minHeightBeforeScroll);
    }, []);

    return (
        <div className="flex flex-col h-[90]">
            <div className={`bg-white p-6 shadow-md rounded-md flex-1 overflow-hidden ${isRTL ? 'rtl' : ''}`}>
                <h2 className={`text-xl font-semibold mb-4  ${isRTL ? 'text-right' : ''}`}>{translations.order_summary}</h2>
                <div className="overflow-y-auto" style={{ maxHeight: `${maxHeight}px` }}>
                    <div className="mb-8">
                        {checkoutItems.length === 0 ? (
                            <p className={` ${isRTL ? 'text-right' : ''}`}>{translations.empty_checkout}</p>
                        ) : (
                            checkoutItems.map((item, index) => (
                                <CheckoutItem
                                    translations={translations}
                                    key={index}
                                    item={item}
                                    index={index}
                                />
                            ))
                        )}
                    </div>
                </div>
                {getTotalAmount() > 0 && (
                    <div className="bg-white p-6">
                        <button
                            className="w-full bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 focus:outline-none focus:ring focus:border-gray-500"
                            onClick={handlePay}
                        >
                            {getTotalAmount().toFixed(2)} DA
                        </button>
                    </div>
                )}

            </div>
        </div>
    );
};

export default Checkout;
