import React, { createContext, useContext, useState } from 'react';

const SuccessContext = createContext();

export const SuccessProvider = ({ children }) => {
    const [success, setSuccess] = useState(false);

    const setSuccessState = (value) => {
        setSuccess(value);
    };

    const contextValue = {
        success,
        setSuccessState,
    };

    return (
        <SuccessContext.Provider value={contextValue}>
            {children}
        </SuccessContext.Provider>
    );
};

export const useSuccessContext = () => {
    return useContext(SuccessContext);
};
