import React from 'react';
import ProductForm from './ProductForm';
import Checkout from './Checkout';

import { useTranslation } from '../context/TranslationContext';

const ShoppingCart = ({ handlePay }) => {
    const { isRTL } = useTranslation();
    return (
        <>
            <div className="flex flex-col md:flex-row h-[86vh]">
                <div className={`md:w-1/2 p-4 flex-1 ${isRTL ? 'rtl' : ''}`}>
                    <div className={`overflow-hidden md:hidden mb-4 ${isRTL ? 'rtl' : ''}`}>
                        <ProductForm />
                    </div>
                    <div className="md:ml-4">
                        {/* CheckoutItems - Displayed above on small screens and to the right on larger screens */}
                        <Checkout
                            handlePay={handlePay}
                        />
                    </div>
                </div>
                <div className="md:w-1/2 p-4 hidden md:block flex-1">
                    <ProductForm />
                </div>
            </div>

        </>
    );
};

export default ShoppingCart;