import React, { useState, useEffect } from 'react';
import wilayaData from '../data/addreess_data.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useSuccessContext } from '../context/SuccessContext'
import { useCheckout } from '../context/CheckoutContext';

import { useTranslation } from '../context/TranslationContext';

const AddressForm = ({ goBack }) => {
    const { translations, isRTL } = useTranslation();
    const { checkoutItems, resetCheckout } = useCheckout();
    const { setSuccessState } = useSuccessContext();
    const [apiError, setApiError] = useState(null);
    const [wilaya, setWilaya] = useState('');
    const [commune, setCommune] = useState('');
    const [wilayaOptions, setWilayaOptions] = useState([]);
    const [communeOptions, setCommuneOptions] = useState([]);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);


    const [errors, setErrors] = useState({
        name: false,
        phone: false,
        wilaya: false,
        commune: false,
    });

    useEffect(() => {

        setWilayaOptions(wilayaData.map(item => item.wilaya));
    }, []);

    useEffect(() => {
        if (wilaya) {
            setErrors((prevErrors) => ({ ...prevErrors, wilaya: false }));
            const selectedWilaya = wilayaData.find((item) => item.wilaya.name === wilaya);
            if (selectedWilaya) {
                setCommuneOptions(selectedWilaya.wilaya.Commune.map((commune) => commune.name));
            }
        }
    }, [wilaya])
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const addRowToGoogleSheet = async (addressData) => {
        const endpoint = `${BASE_URL}/api/add-order`;

        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleString(undefined, { timeZone: 'UTC' });
        const rows = checkoutItems.map((item) => ({
            name: addressData.name,
            address: addressData.address,
            phone: addressData.phone,
            date: formattedDate,
            wilaya: addressData.wilaya,
            product: item.name,
            qte: item.quantity,
            price: item.price,
            total: item.quantity * item.price,
            category: item.category
        }));


        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(rows),
            });

            if (response.ok) {
                // Request was successful
                console.log('Google Sheet update successful');

                setSuccessState(true)
                resetCheckout()
                goBack(false)
            } else {
                const errorData = await response.json();
                setApiError(errorData.error || translations.api_error_default);
                setLoading(false);
                return false;
            }
        } catch (error) {
            // Network error or other exception
            console.error('Error updating Google Sheet:', error.message);
            setApiError(translations.api_error_default);
            setLoading(false);
            return false;
        }
    };

    const handleSaveAddress = async () => {
        const newErrors = {
            name: !name,
            phone: !/^(06|07|05)\d{8}$/.test(phone),
            wilaya: !wilaya,
            commune: !commune,
        };

        if (Object.values(newErrors).some(error => error)) {
            setErrors(newErrors);
            return;
        }

        if (name && phone && wilaya && commune) {
            setLoading(true);
            const newDataObject = {
                name: name,
                phone: phone,
                address: `${wilaya}-${commune}.`,
                wilaya: `${wilaya}`,
            };

            await addRowToGoogleSheet(newDataObject);

            setLoading(false);
        }
    };



    return (
        <div className={`bg-white p-6 rounded-md shadow-md ${isRTL ? 'rtl' : ''}`}>
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 text-white">
                    {/* Use FontAwesome spinner icon */}
                    <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                </div>
            )}

            {apiError && (
                <div className={`mb-4 text-red-500 ${isRTL ? 'rtl' : ''}`}>
                    {isRTL ? (
                        <>
                            <p className={`mb-4 ${isRTL ? 'text-right' : ''}`}>
                                {translations.api_error_default}
                                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                            </p>
                        </>
                    ) : (
                        <>

                            <p className={`mb-4 ${isRTL ? 'text-right' : ''}`}>
                                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                                {translations.api_error_default}
                            </p>
                        </>
                    )}
                </div>
            )}
            <h2 className={`text-xl font-semibold mb-4  ${isRTL ? 'text-right' : ''}`}>
                {translations.address_form_title}
            </h2>
            <div className="mb-4">
                <label htmlFor="name" className={`block text-gray-600 ${isRTL ? 'text-right' : ''}`}>
                    {translations.name}
                </label>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                        setErrors({ ...errors, name: false }); // Clear error when typing
                    }}
                    className={`w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 ${errors.name ? 'border-red-500' : ''} ${isRTL ? 'text-right' : ''}`}
                    dir={isRTL ? 'rtl' : 'ltr'}
                />
            </div>
            <div className="mb-4">
                <label
                    htmlFor="phone"
                    className={`block text-gray-600 ${isRTL ? 'text-right' : ''}`}
                >
                    {translations.phone}
                </label>
                <input
                    type="tel"
                    id="phone"
                    value={phone}
                    maxLength={10}
                    placeholder="e.g., 05xxxxxxxx"
                    onChange={(e) => {
                        setPhone(e.target.value);
                        setErrors({ ...errors, phone: false }); // Clear error when typing
                    }}
                    className={`w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 ${errors.phone ? 'border-red-500' : ''} ${isRTL ? 'text-right' : ''}`}
                    dir={isRTL ? 'rtl' : 'ltr'}
                />
                {errors.name && <p className="text-red-500">{errors.name}</p>}
            </div>
            <div className="mb-4">
                <label htmlFor="wilaya" className={`block text-gray-600 ${isRTL ? 'text-right' : ''}`}>
                    {translations.wilaya}
                </label>
                <select
                    id="wilaya"
                    value={wilaya}
                    onChange={(e) => setWilaya(e.target.value)}
                    className={`w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500  ${errors.wilaya ? 'border-red-500' : ''} ${isRTL ? 'text-right' : ''}`}
                    dir={isRTL ? 'rtl' : 'ltr'}
                >
                    <option value="">{translations.Select_wilay}</option>
                    {wilayaOptions.map((option) => (
                        <option key={option.id} value={option.name}>
                            {`${option.id} - ${option.name}`}
                        </option>
                    ))}
                </select>

            </div>
            <div className="mb-4">
                <label htmlFor="commune" className={`block text-gray-600 ${isRTL ? 'text-right' : ''}`}>
                    {translations.commune}
                </label>
                <select
                    id="commune"
                    value={commune}
                    onChange={(e) => {
                        setCommune(e.target.value);
                        // Clear the error for commune when a value is selected
                        setErrors({ ...errors, commune: false });
                    }}
                    className={`w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500  ${errors.commune ? 'border-red-500' : ''} ${isRTL ? 'text-right' : ''}`}
                    dir={isRTL ? 'rtl' : 'ltr'}
                >
                    <option value="">{translations.Select_commune}</option>
                    {wilayaOptions
                        .find((option) => option.name === wilaya)
                        ?.Commune.map((commune) => (
                            <option key={commune.name} value={commune.name}>
                                {commune.name}
                            </option>
                        ))}
                </select>
                {errors.commune && <p className="text-red-500">{errors.commune}</p>}
            </div>
            <div className="flex space-x-4">
                <button
                    onClick={handleSaveAddress}
                    disabled={loading}
                    className={`flex-1 bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 focus:outline-none focus:ring focus:border-gray-500 ${loading ? 'opacity-50 cursor-not-allowed' : ''} ${loading ? 'bg-gray-400' : 'bg-black'}`}
                >
                    {translations.confirm}
                </button>
                <button
                    onClick={() => goBack(false)}

                    className="ml-0 bg-black text-white py-2 px-4 rounded-md hover:bg-gray-800 focus:outline-none focus:ring focus:border-gray-500"
                >
                    {translations.go_back_btn}
                </button>
            </div>

        </div>
    );
};

export default AddressForm;